import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FloatLabel, { label: "Costo" }, {
      default: _withCtx(() => [
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.hourCost,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.hourCost = $event)),
          mode: "currency",
          currency: "EUR",
          class: "small_input"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}