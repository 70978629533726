import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ShiftCostCell extends Vue {
  @Prop() readonly item!: any;

  @Prop() readonly weekday!: number[];

  get hourCost() {
    return this.item[`weekDay${this.weekday[0]}`].hour_cost;
  }
  set hourCost(value: number) {
    this.weekday.forEach(w => {
      this.item[`weekDay${w}`].hour_cost = value;
    })
  }

  private _init() {
    this.weekday.forEach(w => {
      const wKey = `weekDay${w}`;

      if (!this.item[wKey]) {
        this.item[wKey] = {
          weekday: w,
          hour_cost: 0,
          shift: this.item.shift,
          shift_id: this.item.shift.id,
        }
      }
    })
  }

  created() {
    this._init();
  }
}