import { Options, Vue }       from "vue-class-component";
import { FilterMatchMode } from "primevue/api";

// COMPONENTS
import { AppTable }           from '@sharedComponents';

// SERVICES
import { zonesService }       from "@services/zones.service";

// UTILS
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";

// MODELS
import { ZonesRoutesEnum }    from "../../router";
import { Zone }               from "@/model/Zone";

@Options({
  components: {
    AppTable
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith('zones')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_ZONES);
    }

    next();
  },

  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class ZonesPage extends Vue {

  get service() {
    return zonesService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_ZONES;
  }

  get newZoneRoute() {
    return {
      name   : ZonesRoutesEnum.ZONE_DETAIL,
      params : { zoneId: 'new' }
    };
  }

  get statusOptions() {
    return [
      { value: true,     label: "ATTIVA"    },
      { value: false ,   label: "DISATTIVA" },
    ];
  }

  onRowClick(item: Zone) {
    this.$router.push({ name: ZonesRoutesEnum.ZONE_DETAIL, params: { zoneId: item.id } });
  }

  async toggleZone(zone: Zone) {
    zone.is_active = !zone.is_active;

    await zonesService.updatePatch(zone);

    this.$successMessage(zone.is_active
      ? "Zona attivata" : "Zona disattivata");
  }

  filters: any = null;

  private initFilter() {
    this.filters = {
      global: { 
        value: null, 
        matchMode: FilterMatchMode.CONTAINS 
      },
      is_active: {
        value: null, 
        matchMode: FilterMatchMode.IN 
      }
    };
  }

  created(){
    this.initFilter();
  }
}