import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('zone.list.title')), 1),
      _createVNode(_component_router_link, { to: _ctx.newZoneRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            label: "Aggiungi"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_AppTable, {
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      filtersSchema: _ctx.filters,
      onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRowClick($event.data))),
      onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRowClick($event))),
      showDelete: false
    }, {
      columns: _withCtx(({onApplyFilter}) => [
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.$t('zone.table.name'),
          sortable: true
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "is_active",
          header: _ctx.$t('zone.table.status'),
          sortable: true,
          bodyClass: "p-text-center",
          class: "column-header-center",
          style: { width: '10rem' },
          showFilterMatchModes: false
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_Badge, {
              severity: data.is_active ? 'success' : 'danger'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(data.is_active ? 'Attiva' : 'Disattivata'), 1)
              ]),
              _: 2
            }, 1032, ["severity"])
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_MultiSelect, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => (filterModel.value = $event),
              options: _ctx.statusOptions,
              placeholder: "Attiva/Disattiva",
              class: "p-column-filter",
              optionLabel: "label",
              "option-value": "value"
            }, {
              option: _withCtx(({option}) => [
                _createVNode(_component_Badge, {
                  severity: option.value ? 'success' : 'danger'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1032, ["severity"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          filterclear: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-times",
              class: "p-button-outlined",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({filterCallback}) => [
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-check",
              label: "Applica",
              class: "p-button-success",
              onClick: ($event: any) => (onApplyFilter(filterCallback))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["header"])
      ]),
      _: 1
    }, 8, ["service", "stateKey", "filtersSchema"])
  ]))
}