import {
  computed,
  defineComponent,
  markRaw,
  onMounted,
  reactive,
  readonly, 
  ref, 
  toRaw, 
  toRefs
} from "vue";
import { useGoogleMap }                 from "@/use/googleMaps";

import { ZonesRoutesEnum } from "../../router";

import { useAsyncRequestHandler } from "@/use/waitFor";

import { ShapeInfo, Zone, ZoneEdit }  from "@/model/Zone";
import { Vehicle, VehicleData }                    from "@/model/Vehicle";

import { zonesService } from "@services/zones.service";

import ShiftCosts 
  from "./ShiftCosts/ShiftCosts.vue";
import ShiftCostsShortcut 
  from "./ShiftCosts/ShiftCostsShortcut/ShiftCostsShortcut.vue";

import { DeleteMenu } from "./DeleteMenu";

import {
  VehicleAutocomplete
} from "@/components";

export default defineComponent({
  components: {
    ShiftCosts,
    ShiftCostsShortcut,
    VehicleAutocomplete
  },

  props: {
    zoneId: {
      type: String
    }
  },

  data() {
    return {
      isEditName: false,
      showManual: false
    }
  },

  setup(props) {
    const state = reactive({
      activeIndexes: [2, 3] as any,
      isDrawing: null
    });

    const colors = readonly([
      "#01cc12",
      "#2f1dca",
      "#00c2c9",
      "#c6d400",
      "#ff0000"
    ]);

    const zone = ref(new Zone());
    const zoneEdit = ref(new ZoneEdit());

    const map = ref<google.maps.Map>(null);

    const isNew = computed(() => props.zoneId === 'new');
    const backRoute = computed(() => ({ name: ZonesRoutesEnum.ZONE_LIST }));

    const {
      isLoading,
      $waitFor
    } = useAsyncRequestHandler();

    const {
      loadGoogle,
      buildMap,
    } = useGoogleMap();

    function initZone() {
      if (isNew.value) {
        zone.value = new Zone();
        zoneEdit.value = ZoneEdit.fromZone(zone.value as any);
        isLoading.value = false;
      } else {
        return $waitFor(
          async () => {
            zone.value = await zonesService.getById(+props.zoneId);
            zoneEdit.value = ZoneEdit.fromZone(zone.value as any);
          },
          "Caricamento città non riuscito"
        )
      }
    }

    function addMapEventListener(map: google.maps.Map) {
      map.addListener(
        "click",
        (evt: google.maps.MapMouseEvent) => {
          if (!state.isDrawing) return;

          const lng = evt.latLng.lng();
          const lat = evt.latLng.lat();

          const l = 10 * 0.008;

          const shape = zoneEdit.value.shapes[state.isDrawing - 1];
          shape.coordinates = [
            [lng - l, lat + l],
            [lng + l, lat + l],
            [lng + l, lat - l],
            [lng - l, lat - l],
          ];

          const polygon = drawPolygon(
            map,
            shape.coordinates,
            shape.zIndex,
            shape.isActive,
            colors[state.isDrawing - 1]
          );

          shape.polygonRef = polygon;

          attachListeners(polygon, shape);

          map.setCenter(evt.latLng);
          map.setZoom(11);
          
          stopDrawing();
        }
      )
    }

    function stopDrawing() {
      state.isDrawing = null;

      map.value.setOptions({
        draggableCursor: ''
      })
      
      zoneEdit.value.shapes.forEach( 
        s => s.polygonRef?.set('clickable', true)
      );
    }

    function attachContextMenu(p: google.maps.Polygon, map) {
      const deleteMenu = new DeleteMenu();

      google.maps.event.addListener(
        p, 'contextmenu', (e: any) => {
          if (e.vertex == undefined) {
            return;
          }

          deleteMenu.open(map, p.getPath(), e.vertex);
        }
      )
    }

    function drawPolygon(map, coordinates, zIndex, isActive, color = "#FF0000") {
      const paths = coordinates
        .map(([lng, lat]) => new google.maps.LatLng(lat, lng));

      const p = markRaw(
        new google.maps.Polygon({
          map,
          paths,

          strokeColor: color,
          strokeOpacity: isActive ? 0.8 : 0.2,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: isActive ? 0.35 : 0.15,

          draggable: true,
          editable: true,
          zIndex,
        })
      );

      attachContextMenu(p, map);

      return p;
    }

    function attachListeners(polygon, shape: ShapeInfo) {
      google.maps.event.addListener(
        polygon,
        'mouseover',
        () => {
          polygon.set("strokeWeight", 4);
        }
      );

      google.maps.event.addListener(
        polygon,
        'mouseout',
        () => {
          polygon.set("strokeWeight", 2);
        }
      );
    }

    function drawShapes() {
      const rawMap = toRaw(map.value);

      zoneEdit.value.shapes
        ?.filter(x => x.coordinates?.length)
        .forEach((shape, i) => {

          const polygon = drawPolygon(
            rawMap,
            shape.coordinates,
            shape.zIndex,
            shape.isActive,
            colors[i]
          );

          shape.polygonRef = polygon;

          attachListeners(polygon, shape);

        })
    }

    function getBounds() {
      if (!zoneEdit.value.existingShapes?.length) {
        return null;
      }

      const bounds = new google.maps.LatLngBounds();

      zoneEdit.value.existingShapes
        .map(s => s.coordinates)
        .flat(1).forEach(([lng, lat]) => {
          bounds.extend(new google.maps.LatLng(lat, lng))
        });

      return bounds;
    }

    function onShortcutSubmit({ lunVen, saturday, sunday }) {
      zoneEdit.value.shift_costs_dataset.forEach(x => {
        [1, 2, 3, 4, 5].forEach(d => x[`weekDay${d}`].hour_cost = lunVen);

        x[`weekDay${6}`].hour_cost = saturday;
        x[`weekDay${7}`].hour_cost = sunday;
      })
    }

    onMounted(async () => {
      await loadGoogle();

      await initZone();

      map.value = await buildMap(
        document.getElementById('map'),
        getBounds()
      );

      const rawMap = toRaw(map.value);

      addMapEventListener(rawMap);

      drawShapes();
    });

    const selectedVehicleIds = computed( () => 
      zoneEdit.value.vehicle_data?.map(v => v.vehicle_id) || []
    );
  
  
    function onSelect(value : Vehicle) {
      zoneEdit.value.vehicle_data = [
        ...(zoneEdit.value.vehicle_data || []),
        {
          id          : null,
          extra_cost  : null,
          extra_price : null,
          entity_id   : null,
          is_enabled  : false,
          vehicle_id  : value.id,
          zone_id     : zoneEdit.value.id,
          vehicle     : value
        }
      ];
    }

    function removeVehicle(value: VehicleData){
      const idx = zoneEdit.value.vehicle_data.indexOf(value);
      zoneEdit.value.vehicle_data.splice(idx, 1);
    }

    return {
      onShortcutSubmit,
      ...toRefs(state),
      colors,

      zone,
      zoneEdit,
      isLoading,

      initZone,
      isNew,
      backRoute,
      map,
      stopDrawing,

      selectedVehicleIds,
      onSelect,
      removeVehicle
    }
  },

  methods: {

    showDrawButton(data: ShapeInfo) {
      const condition =
        // Non è già presente su mappa E 
        !data.polygonRef &&
        // Modalità disegno off o attiva su un altra area 
        (!this.isDrawing || this.isDrawing !== data.idx);

      return condition;
    },

    /**
     * In base allo stato attivo/disattivo dell'area 
     * ne imposta l'opacità della linea e dello riempimento
     */
    onActiveChange({ isActive, polygonRef }: ShapeInfo) {
      if (!polygonRef) return;

      if (isActive) {
        polygonRef.set('fillOpacity', 0.35);
        polygonRef.set('strokeOpacity', 0.8);
      } else {
        polygonRef.set('fillOpacity', 0.15);
        polygonRef.set('strokeOpacity', 0.2);
      }
    },

    /**
     * Abilita la modifica del nome della zona 
     */
    toggleEditName() {
      this.isEditName = !this.isEditName;

      this.$nextTick(() => {
        this.isEditName && (this.$refs.inputName as any).$el.focus();
      });
    },

    toggleVisibility(data: ShapeInfo) {
      data.isVisible = !data.isVisible;
      data.polygonRef.setVisible(data.isVisible);
    },

    /**
     * Abilita l'inserimento di una specifica area
     * @param idx : indice dell'area
     */
    drawShape(idx: number) {
      this.isDrawing = idx;

      this.map.setOptions({
        draggableCursor: 'crosshair'
      });

      this.zoneEdit.shapes.forEach( (s, i) => {
        if ( i !== idx) {
          (s.polygonRef as google.maps.Polygon)?.set('clickable', false);
        }
      });
    },

    stopDrawShape() {
      this.stopDrawing();
    },

    deleteShape(data: ShapeInfo) {
      data.coordinates = [];
      data.polygonRef?.setMap(null);
      data.polygonRef = null;
    },

    restore(data: ShapeInfo) {
      this.deleteShape(data);

      data.isActive = false;
      data.isVisible = true;
      data.cost = 0;
      data.price = 0;
    },

    checkRequired() {
      const requiredFields = [
        "name"
      ]
    },

    async onSave() {
      if ( !this.zoneEdit?.name ) {
        this.$errorMessage("Inserire almeno il nome della zona");
        return;
      }

      this.$waitFor(
        async () => {
          const zoneToSave = this.zoneEdit.toZone();

          if (this.isNew) {
            const response  = await zonesService.create(zoneToSave as any);
            await this.$router.replace({
              name: ZonesRoutesEnum.ZONE_DETAIL, 
              params: { zoneId: response.id } 
            });
            this.initZone();
          } else {
            await zonesService.updatePatch(zoneToSave as any);
          }

          this.$successMessage("Operazione completata");
        },
        "Salvataggio non riuscito"
      )
    },

    async onDelete() {
      this.$confirmMessage(
        this.$t(`Procedere con la cancellazione della zona: ${this.zone.name}`)
      )
        .then(async (response) => {
          if (response) {
            await zonesService.remove(this.zone as any);

            this.$router
              .replace(this.backRoute)
              .then(() => this.$successMessage("Zonca cancellata correttamente"));
          }
        })
    },

    toggleManual() {
      this.showManual = true;
    }
  },

});