import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_ShiftCostCell = _resolveComponent("ShiftCostCell")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.zoneEdit.shift_costs_dataset
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        style: { minWidth: '180px' },
        frozen: "",
        header: "Fascia oraria"
      }, {
        body: _withCtx(({data}) => [
          _createElementVNode("div", null, _toDisplayString(data.shift.start_time) + " - " + _toDisplayString(data.shift.end_time), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        style: { minWidth: '180px' },
        header: "Lunedì - Venerdì"
      }, {
        body: _withCtx(({data}) => [
          _createVNode(_component_ShiftCostCell, {
            item: data,
            weekday: [1,2,3,4,5]
          }, null, 8, ["item"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        style: { minWidth: '180px' },
        header: "Sabato"
      }, {
        body: _withCtx(({data}) => [
          _createVNode(_component_ShiftCostCell, {
            item: data,
            weekday: [6]
          }, null, 8, ["item"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        style: { minWidth: '180px' },
        header: "Domenica"
      }, {
        body: _withCtx(({data}) => [
          _createVNode(_component_ShiftCostCell, {
            item: data,
            weekday: [7]
          }, null, 8, ["item"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value"]))
}