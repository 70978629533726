import { ref } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { useToast } from "primevue/usetoast";

export function useAsyncRequestHandler(){
    const isLoading = ref(false); 

    const $router = useRouter();
    const $route  = useRoute();
    const $toast  = useToast();


    async function $waitFor (request: () => Promise<any>, errorMessage: string = "Qualcosa è andato storto") {
        try {
            isLoading.value = true;
            
            await request();
    
        } catch (error) {
            console.error(error);
            
            const currentRouteName = $route?.name?.toString();

            if ( error?.status === 404 && currentRouteName.includes('detail') ){
                await $router.replace({
                    name: currentRouteName.replace('detail', 'list')
                });
            }

            $toast.add({
                severity: 'error',
                summary: 'Operazione interrotta',
                detail: errorMessage,
                life: 3000
            });
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        $waitFor
    }
}