
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    emits: ["submit"],

    setup(_, {emit}) {
      const sunday    = ref(null);
      const lunVen    = ref(null);
      const saturday  = ref(null);

      function onSubmit(){
        emit('submit', {
          lunVen   : lunVen.value,
          saturday : saturday.value,
          sunday   : sunday.value
        });
      }

      return {
        lunVen,
        saturday,
        sunday,

        onSubmit
      };
    },
  });
