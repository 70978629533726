import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { ZoneEdit } from "@/model/Zone";

import { shiftsService } from "@services/shift.service";
import { Shift } from "@/model/Shift";

import ShiftCostCell from "./ShiftCostCell/ShiftCostCell.vue";
import moment, { HTML5_FMT } from "moment";

@Options({
  components: {
    ShiftCostCell
  }
})
export default class ShiftCosts extends Vue {
  @Prop() readonly zoneEdit!: ZoneEdit;

  shifts: Shift[] = null;


  get selectedShiftIds() {
    return this.zoneEdit?.shift_costs_dataset?.map(x => x.shift?.id);
  }

  get nonSelectedShift() {
    return this.shifts?.filter(s => !this.selectedShiftIds.includes(s.id));
  }

  get columnStyle() {
    return { minWidth: '180px' };
  }

  get ready() {
    return {
      zoneEdit: this.zoneEdit,
      shifts: this.shifts
    }
  }

  @Watch('ready', { immediate: true })
  onZoneChange({ shifts, zoneEdit }) {
    if (zoneEdit?.shift_costs_dataset && shifts?.length) {
      const missing = [...this.nonSelectedShift];

      if (missing?.length) {
        missing.forEach(this.addMissing);

        // SORT 
        (zoneEdit?.shift_costs_dataset as any[])
          .sort((x, y) => {
            const momentStart = moment(x.shift.end_time, HTML5_FMT.TIME);
            const momentEnd   = moment(y.shift.start_time, HTML5_FMT.TIME);

            return momentStart.isBefore(momentEnd) ? -1 : 1;
          });
      }

    }
  }

  async loadShifts() {
    this.shifts = await shiftsService.getAll();
  }

  addMissing(value) {
    this.zoneEdit.shift_costs_dataset.push({
      shift: value,
      shift_id: value.id
    });
  }

  mounted() {
    this.loadShifts();
  }

}